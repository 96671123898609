import React from 'react';
//import logo from './priprema.png';
import './App.css';
import "./fonts/GTWalsheimProRegular.ttf"
import "./fonts/GTWalsheimProLight.ttf"
import "./fonts/GTWalsheimProUltraLight.ttf"
import Header from './Components/Header'
import AboutUs from './Components/AboutUs'
import OurMission from './Components/OurMission'
import ContactUs from './Components/ContactUs'
import Footer from './Components/Footer'

function App() {

  return (
    <div className="App">
      <Header />
      <div id="link-1"><AboutUs /></div>
      <div id="link-2"><OurMission /></div>
      <div id="link-3"><ContactUs /></div>
      <Footer />
    </div>
  );

  /*return (
    <div className="App">
      <div className="App-header">
      <img src={logo} className="Maintenance-logo" alt="logo" />
      </div>
    </div>
  );*/
}

export default App;
