import React from 'react';
import './AboutUs.scss';
import { Helmet } from "react-helmet";
//import icon from '../Assets/Images/ArtifitIcon.png'
//import { useLayoutEffect, useRef, useState } from 'react';



function AboutUs() {

  return (
    <div className='content'>
      <Helmet>
        <title>Artifit - About us</title>
        <meta
          name="description"
          content="Information about the Artifit company"
        />
      </Helmet>
      <svg className='about-us-icon' width="1004" height="483" viewBox="0 0 1004 483" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g filter="url(#filter0_i_610_11)">
          <circle cx="930.116" cy="122.397" r="73.8187" fill="#F7F7F7" />
        </g>
        <g filter="url(#filter1_i_610_11)">
          <path d="M860.246 207.797C830.887 178.439 783.282 178.439 753.975 207.797L733.92 227.852C722.807 238.655 707.766 245.426 691.019 245.426C675.926 245.426 662.332 239.792 651.581 230.85L451.599 30.8688C412.937 -7.79397 351.79 -10.1199 310.387 23.7875C307.389 26.1652 304.495 28.7496 301.704 31.489L30.8578 302.335C-10.2859 343.479 -10.2859 410.208 30.8578 451.404C72.0016 492.547 138.731 492.547 179.926 451.404L267.848 363.482C295.708 335.622 334.112 318.255 376.652 318.255C420.483 318.255 459.973 336.604 488.04 365.963H488.556L573.377 450.783C609.92 487.327 666.571 491.359 707.663 462.982C712.987 459.829 718.104 456.211 722.704 451.662L860.246 314.12C889.553 284.761 889.553 237.156 860.246 207.797Z" fill="white" />
        </g>
        <defs>
          <filter id="filter0_i_610_11" x="846.772" y="48.5781" width="157.163" height="153.353" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
            <feOffset dx="-9.525" dy="5.715" />
            <feGaussianBlur stdDeviation="7.62" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow_610_11" />
          </filter>
          <filter id="filter1_i_610_11" x="-9.525" y="0" width="891.752" height="487.977" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
            <feOffset dx="-9.525" dy="5.715" />
            <feGaussianBlur stdDeviation="7.62" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow_610_11" />
          </filter>
        </defs>
      </svg>
      <div className='about-us'>
        <div className='about-us-call'>
          <h2 className='about-us-title'>Own the information.</h2>
          <div>
            <h2 className='about-us-decision'>Make the best <span className='about-us-decision-black'>decision.</span></h2>
          </div>
          <div className='about-us-btn'>
            <a href="mailto:office@artifit.rs" rel="noreferrer" target="_blank">
              <button className='about-contact-button'>
                Get in touch
              </button>
            </a>
          </div>
        </div>

      </div>
      <div className='about-us-info'>
        <h3 className='about-us-info-one'>We are a company focused on developing proprietary intelligent technology and services.</h3>
        <h3 className='about-us-info-two'>Using data analytics and machine learning, we provide deep insights & predictions for your business.
          That way, we help you make better decisions by understanding past
          performance and predicting future outcomes.</h3>
      </div>
    </div>

  )
}

export default AboutUs