import React from 'react';
import './ContactUs.scss';
import { Helmet } from "react-helmet";
//import { useLayoutEffect, useRef, useState } from 'react';

function ContactUs() {

  return (
    <div className='contact-us'>
      <Helmet>
        <title>Artifit - Contact us</title>
        <meta
          name="description"
          content="How to contact Artifit company"
        />
      </Helmet>
      <div className='contact-message'>
        <p>Making informed business decisions is easier with our services in various sectors,
          including energy, sport, and digital marketing.</p>
      </div>
      <div className='contact-message'>
        <p>We are committed to empowering you
          with the data-driven insights you need to stay ahead of the competition and succeed in the digital age.</p>
      </div>
      <div className='line'></div>
      <div>
        <h2 className='contact-action'>
          Have any project in mind?
        </h2>
      </div>
      <div>
        <a href="mailto:office@artifit.rs" rel="noreferrer" target="_blank">
          <button className='contact-button'>
            Get in touch
          </button>
        </a>
      </div>
    </div>
  )
}

export default ContactUs