import React from 'react';
import './Footer.scss';
import logoWhite from '../Assets/Images/ArtifitLogoOld.png';

function Footer() {
    return (
        <div className='footer'>
            <div className='footer-info'>
                <div>
                    <a href="https://www.artifit.rs" rel="noreferrer"><img className='footer-info-logo' src={logoWhite} alt="" /></a>
                    <p className='footer-info-text'>Most of the insights you can get</p>
                    <div className='footer-info-line'></div>
                </div>
                <p className='footer-info-rights'>Copyright 2024 All rights reserved</p>
            </div>
            <div className='footer-contact'>
                <p>Contact us</p>
                <div className='footer-info-line-c'></div>
                <div className='footer-contact-items'>
                    <ul>
                        <li>
                            <a href="tel:+381114039271" rel="noreferrer" target="_blank">
                                <div className='inline'>
                                    <svg className='svg-icon' xmlns="http://www.w3.org/2000/svg" width="28" height="25" viewBox="0 0 28 28" fill="none">
                                        <path d="M0 5.50117C0.0484102 5.27503 0.0887519 5.04888 0.14523 4.83082C0.298529 4.22507 0.580922 3.67587 1.01661 3.23165C1.83152 2.39977 2.64642 1.57595 3.47746 0.768296C4.16327 0.106017 4.96204 -0.225123 5.8899 0.17063C6.24491 0.324085 6.57571 0.566382 6.85811 0.832909C8.03609 1.97978 9.18986 3.15089 10.3436 4.31392C10.9004 4.87928 11.3038 5.50925 11.1908 6.35729C11.1101 6.97111 10.7632 7.43147 10.3436 7.85146C9.65783 8.53797 8.97201 9.2164 8.2862 9.90291C7.93926 10.2502 7.9312 10.3471 8.13291 10.8075C8.91554 12.552 10.1097 14.0058 11.3845 15.395C12.6512 16.768 14.047 18.0037 15.6365 19.0052C16.1851 19.3525 16.7822 19.6352 17.355 19.9421C17.6213 20.0875 17.8149 19.9663 18.0005 19.7806C18.7186 19.0537 19.4367 18.3349 20.1548 17.616C20.5662 17.2041 21.0261 16.8811 21.6313 16.8003C22.2283 16.7276 22.7447 16.9053 23.2127 17.2526C23.374 17.3737 23.5193 17.511 23.6645 17.6564C24.8102 18.7952 25.9559 19.9259 27.0855 21.0809C27.3679 21.3717 27.6341 21.7109 27.8036 22.0743C28.1586 22.8335 28.0214 23.5927 27.4808 24.1904C26.5772 25.1919 25.6332 26.1611 24.6408 27.0737C23.7452 27.9056 22.5995 28.0671 21.4215 27.9783C19.4044 27.8329 17.5567 27.1383 15.7736 26.2499C12.9416 24.8446 10.3759 23.0677 8.11677 20.8628C4.98625 17.8099 2.45278 14.337 0.887519 10.2179C0.484102 9.15986 0.153299 8.08568 0.0564785 6.94688C0.0484102 6.89842 0.0242051 6.85804 0.00806836 6.80958C0 6.3896 0 5.94538 0 5.50117Z" fill="white" />
                                    </svg>
                                    <div className='inline-text'>+381 11 40 39 271</div>
                                </div>
                            </a>
                        </li>
                        <li>
                            <a href="mailto:office@artifit.rs" rel="noreferrer" target="_blank">
                                <div className='inline'>
                                    <svg className='svg-icon' xmlns="http://www.w3.org/2000/svg" width="30" height="24" viewBox="0 0 34 24" fill="none">
                                        <path d="M33.625 21.0812C33.5348 21.3603 33.4445 21.6394 33.3295 21.9924C29.9475 18.5447 26.6148 15.1381 23.356 11.8218C26.5984 8.51369 29.9475 5.10708 33.3295 1.65942C33.4445 2.0124 33.5348 2.29149 33.625 2.57059C33.625 8.73533 33.625 14.9083 33.625 21.0812Z" fill="white" />
                                        <path d="M31.9009 0.321453C31.8435 0.387123 31.786 0.452792 31.7286 0.510253C27.4929 4.72132 23.2572 8.9406 19.0215 13.1517C18.1185 14.0464 17.0514 14.3748 15.8447 13.9397C15.4179 13.7837 14.9992 13.5128 14.6709 13.1927C10.3941 8.96522 6.14202 4.72132 1.8817 0.485627C1.83245 0.436375 1.78319 0.370705 1.65186 0.222949C2.18542 0.140861 2.6369 0.0177308 3.09659 0.0177308C5.21443 -0.00689531 7.34049 0.00131339 9.46655 0.00131339C16.4604 0.00131339 23.4542 0.00131339 30.448 0.0095221C30.9323 0.0095221 31.4084 0.124444 31.8927 0.190114C31.8927 0.239366 31.8927 0.280409 31.9009 0.321453Z" fill="white" />
                                        <path d="M21.755 13.1763C25.1287 16.6157 28.4615 20.0223 31.8024 23.4289C31.5397 23.4864 31.1868 23.5685 30.8256 23.6342C30.7107 23.6588 30.5875 23.6424 30.4644 23.6424C21.3609 23.6424 12.2575 23.6424 3.15403 23.6424C2.66151 23.6424 2.17719 23.5849 1.82422 23.4043C5.14875 20.0141 8.48148 16.6157 11.8553 13.1845C12.3314 13.6606 12.7664 14.0956 13.1933 14.5307C13.7186 15.0643 14.3179 15.5158 15.032 15.762C17.0432 16.468 18.8162 16.0657 20.3513 14.5964C20.811 14.1613 21.246 13.7016 21.755 13.1763Z" fill="white" />
                                        <path d="M0.3304 1.66772C3.69597 5.09896 7.02871 8.50558 10.2876 11.8219C7.02871 15.1464 3.68776 18.553 0.355027 21.9514C0.305774 21.9432 0.256522 21.9268 0.20727 21.9186C0.1416 21.4671 0.0102609 21.0074 0.0102609 20.556C-0.00615653 17.6583 0.00205218 14.7524 0.00205218 11.8547C0.00205218 8.94885 0.00205218 6.03476 0.00205218 3.12067C0.00205218 2.63635 0.0677218 2.17666 0.3304 1.66772Z" fill="white" />
                                    </svg>
                                    <div className='inline-text'>office@artifit.rs</div>
                                </div>
                            </a>
                        </li>
                        <li>
                            <a href="https://www.google.com/maps/place/Artifit/@44.8075391,20.3969005,575m/data=!3m2!1e3!4b1!4m6!3m5!1s0x475a6fc510b5a0eb:0x614dafcde4e69381!8m2!3d44.8075391!4d20.3994754!16s%2Fg%2F11t5nptf9d?entry=ttu" rel="noreferrer" target="_blank">
                                <div className='inline'>
                                    <svg className='svg-icon' xmlns="http://www.w3.org/2000/svg" width="29" height="30" viewBox="0 0 29 38" fill="none">
                                        <g clip-path="url(#clip0_188_756)">
                                            <path d="M13.8187 37.9998C11.6589 35.9079 9.67502 33.6376 7.81819 31.2682C5.64863 28.4923 3.67453 25.5876 2.14998 22.3854C1.18247 20.3629 0.420198 18.2711 0.127014 16.0306C-0.302988 12.7391 0.351788 9.65591 2.0718 6.84036C4.33908 3.12264 7.64228 0.892014 11.8934 0.178213C17.5128 -0.763608 23.2689 2.0222 26.1324 7.02872C28.3801 10.9645 28.6244 15.0788 27.1683 19.3517C26.0835 22.5341 24.4319 25.4091 22.5555 28.1553C20.2101 31.5756 17.5421 34.7183 14.6298 37.6528C14.5125 37.7717 14.3855 37.8808 14.2584 37.9998C14.1119 37.9998 13.9653 37.9998 13.8187 37.9998ZM14.0532 20.7892C17.5616 20.7892 20.4642 17.8646 20.4739 14.3154C20.4837 10.6969 17.6105 7.73261 14.0825 7.73261C10.4862 7.73261 7.61296 10.6374 7.60319 14.2659C7.60319 17.8547 10.4959 20.7793 14.0532 20.7892Z" fill="white" />
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_188_756">
                                                <rect width="28.087" height="38" fill="white" />
                                            </clipPath>
                                        </defs>
                                    </svg>
                                    <div className='inline-text'>Uroša Martinovića 18</div>
                                </div>
                            </a>
                        </li>
                        <li>
                            <a href="https://www.linkedin.com/company/artifit-d-o-o" rel="noreferrer" target="_blank">
                                <div className='inline'>
                                    <svg className='svg-icon' xmlns="http://www.w3.org/2000/svg" width="33" height="30" viewBox="0 0 33 34" fill="none">
                                        <path d="M33 33.0127C30.7304 33.0127 28.4694 33.0127 26.1653 33.0127C26.1653 32.8666 26.1653 32.7376 26.1653 32.6172C26.1653 28.9119 26.1739 25.198 26.1481 21.4926C26.1395 20.7275 26.0708 19.9452 25.916 19.1886C25.4948 17.0823 24.1192 16.257 22.0301 16.4805C20.0958 16.6869 19.0212 17.7357 18.6945 19.7388C18.5655 20.547 18.5053 21.3809 18.4968 22.2062C18.4796 25.6622 18.4882 29.1268 18.4882 32.5829C18.4882 32.7118 18.4882 32.8408 18.4882 32.9869C16.2099 32.9869 13.9489 32.9869 11.6707 32.9869C11.6707 25.6536 11.6707 18.3289 11.6707 10.987C13.8457 10.987 16.0122 10.987 18.2216 10.987C18.2216 11.9671 18.2216 12.9643 18.2216 14.039C18.3248 13.9186 18.3678 13.8842 18.4022 13.8326C19.5542 11.9155 21.2994 10.8667 23.4573 10.5228C25.1337 10.2563 26.8015 10.3938 28.4092 10.9698C30.5069 11.7264 31.7276 13.291 32.3552 15.3715C32.6991 16.5063 32.8539 17.6755 32.9312 18.8619C32.9398 19.0425 32.9742 19.223 32.9914 19.3949C33 23.9428 33 28.4735 33 33.0127Z" fill="white" />
                                        <path d="M4.44165 0C4.74254 0.0859707 5.04344 0.14615 5.33574 0.257912C7.16692 0.937081 8.22435 2.82844 7.84608 4.74558C7.48501 6.60255 5.79139 7.99527 3.92582 7.96948C1.98288 7.93509 0.340845 6.49938 0.0485446 4.58224C-0.286741 2.43297 1.14897 0.438451 3.29824 0.0601795C3.35842 0.0515824 3.41859 0.0257912 3.47877 0C3.79687 0 4.11496 0 4.44165 0Z" fill="white" />
                                        <path d="M7.35586 33.004C5.08623 33.004 2.8338 33.004 0.555577 33.004C0.555577 25.6621 0.555577 18.3374 0.555577 10.9869C2.81661 10.9869 5.06904 10.9869 7.35586 10.9869C7.35586 18.3116 7.35586 25.6363 7.35586 33.004Z" fill="white" />
                                    </svg>
                                    <div className='inline-text'>Artifit doo</div>
                                </div>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div >
    )
}

export default Footer;